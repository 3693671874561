// src/components/assistant/ConfigurationTab.js
import React from 'react';
import {
  Grid, GridItem, VStack, FormControl, FormLabel, Input, Slider, SliderTrack,
  SliderFilledTrack, SliderThumb, Select, Text, Checkbox, HStack, Button, Tooltip,
  Container, Flex
} from '@chakra-ui/react';
import { MdAutorenew } from 'react-icons/md';


const ConfigurationTab = ({
  formState,
  handleInputChange,
  handleSliderChange,
  handleVoiceRefresh,
  handleCheckboxChange,
  setIsDialogOpen,
  voiceLoading,
  voices,
  voiceRef
}) => {
    return (
    <Grid templateColumns={{
      base: "repeat(1, 1fr)",
      md: "repeat(1, 1fr)",
      lg: "repeat(2, 1fr)"
    }} gap={6}>
      {/* Left Column */}
      <GridItem>
        <VStack spacing={4} align="stretch">
        <Container bg="gray.100" p={4} borderRadius="md" borderWidth={1} borderColor="gray.900">
        <FormControl isRequired pb={5}>
            <FormLabel>Cartesia Voice ID</FormLabel>
            <Text fontSize="sm">To preview the voice, login to <a rel="noreferrer" target="_blank" href="https://play.cartesia.ai/">https://play.cartesia.ai/</a></Text>
            <HStack spacing='5px'>
                <Select 
                ref={voiceRef}
                name="cartesiaVoiceId" bg="gray.900" color="gray.100"
                    value={formState.cartesiaVoiceId}
                    onChange={handleInputChange} required={true} colorScheme="orange">
                        {voices.map((voice) => (
                            <option key={voice.id} value={voice.id}>
                            {voice.name}
                        </option>
                        ))}

                </Select>
                <Tooltip label="Reload from Cartesia Voices" aria-label="Refresh Cartesia Voices">
                    <Button onClick={handleVoiceRefresh} variant="ghost" p={0} disabled={voiceLoading}>
                        <MdAutorenew size="1.5rem" color="black" style={{
                            animation: voiceLoading ? 'spin 1s linear infinite': 'none'
                        }}/>
                    </Button>
                </Tooltip>
            </HStack>
        </FormControl>
        <FormControl isRequired>
            <Flex alignItems="center">
                <Text mr={2}>Voice Speed:</Text>
                <Text color="brand.400">{formState.voiceSpeed}</Text>
            </Flex>
            <Slider
                aria-label="voice-speed-slider"
                value={formState.voiceSpeed}
                onChange={(val) => handleSliderChange(val, 'voiceSpeed')}
                min={-1}
                max={1}
                step={0.1}
            >
                <SliderTrack bg="gray.600">
                <SliderFilledTrack bg="blue.400" />
                </SliderTrack>
                <SliderThumb />
            </Slider>
        </FormControl>

        </Container>
        

          {/* hang up timeout */}
          <Flex alignItems="center">
            <Text mr={2}>Max Call Time in seconds:</Text>
            <Text color="brand.400">{formState.hangupTimeout}</Text>
          </Flex>
          <Slider
            aria-label="hangup-timeout-slider"
            value={formState.hangupTimeout}
            onChange={(val) => handleSliderChange(val, 'hangupTimeout')}
            min={30}
            max={600}
            step={10}
          >
            <SliderTrack bg="gray.600">
              <SliderFilledTrack bg="blue.400" />
            </SliderTrack>
            <SliderThumb />
          </Slider>

        <FormControl>
          <FormLabel>Hangup Trigger Words</FormLabel>
          <Input
            placeholder="Enter words to trigger hangup. Separate multiple trigger with commas"
            name="hangupTrigger"
            value={formState.hangupTrigger}
            onChange={handleInputChange}
            bg="gray.900"
            color="gray.200"
          />
          </FormControl>

        </VStack>
      </GridItem>
      <GridItem>
        <VStack spacing={4} align="stretch">
        <FormControl isRequired>
        <FormLabel>Deepgram Model ID</FormLabel>
            
            <Select name="transcriptionModelId" bg="gray.900" color="gray.100"
            value={formState.transcriptionModelId || 'nova-2'}
            onChange={handleInputChange} required={true} colorScheme="orange">
                <option value='nova-2'>Nova-2</option>
                <option value='base'>Enhanced</option>
                <option value='enhanced'>Base</option>
            </Select>
            
          
        </FormControl>

        <Container bg="gray.100" p={4} borderRadius="md" borderWidth={1} borderColor="gray.900">
            <FormControl isRequired pb={5}>
                <FormLabel>Idle Message</FormLabel>
                <Input
                    placeholder="Enter idle message"
                    name="idleMessage"
                    value={formState.idleMessage || ''}
                    onChange={handleInputChange}
                    bg="gray.900"
                    color="gray.200"
                />
            </FormControl>
            <Flex alignItems="center">
                <Text mr={2}>Idle Timeout in seconds:</Text>
                <Text color="brand.400">{formState.callTimeout || 20}</Text>
            </Flex>
            <Slider
                aria-label="call-timeout-slider"
                value={formState.callTimeout || 20}
                onChange={(val) => handleSliderChange(val, 'callTimeout')}
                min={5}
                max={60}
                step={1}
            >
                <SliderTrack bg="gray.600">
                <SliderFilledTrack bg="blue.400" />
                </SliderTrack>
                <SliderThumb />
            </Slider>
          </Container>

          <Checkbox
            name="twilioRecording"
            isChecked={formState.twilioRecording || false}
            onChange={handleCheckboxChange}
          >
            Enable Call Recording (There is additional charge for this. Refer to Twilio pricing)
          </Checkbox>
          

          {/* Delete button */}
          <Button
            colorScheme="red"
            onClick={() => setIsDialogOpen(true)}
            size="sm"
          >
            Delete
          </Button>
        </VStack>
      </GridItem>
    </Grid>
)};

export default ConfigurationTab;