import React, { useState,useEffect } from 'react';
import { Box, List, ListItem, Icon, Flex, Image, Button, useBreakpointValue } from '@chakra-ui/react';
import { MdDashboard, MdSettings, MdPerson, MdLogout, MdAccountCircle, MdPhoneIphone, MdLibraryBooks,
    MdPayment, MdAdminPanelSettings
 } from 'react-icons/md';
import { RiFunctionAddLine } from "react-icons/ri";
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import axios from 'axios';

// Navigation component
const Navigation = () => {
  const navigate = useNavigate();
  const iconSize = useBreakpointValue({ base: '2rem', md: '1.5rem' });
  const [ isAdmin, setIsAdmin ] = useState(false);

  useEffect(() => {
    const checkAuthentication = async () => {
      try {
        // Make API call to check if user is authenticated
        const response = await axios.get('/api/checkIsAdmin');
        setIsAdmin(response.data.isAdmin);
      } catch (error) {
        console.error('Error checking session:', error);
        setIsAdmin(false);  // Assume not authenticated if there's an error
      } finally {
      }
    };

    checkAuthentication();  // Check authentication when component mounts
  }, []);

  // Logout function
  const handleLogout = async () => {
    try {
      await axios.post('/api/logout');
      navigate('/login');
    } catch (error) {
      console.error('Error logging out:', error);
    }
  };

  

  return (
    <Flex direction="column"  alignItems="center" justifyContent="space-between" bg="gray.800" color="white" p={4} height="100vh">
      <Box>
        <Flex alignItems="center" mb={6} justifyContent="center">
          {/* Logo in the top left corner */}
          <Image
            src="/logo.png"
            alt="Logo"
            width="200px"
            display={{ base: 'none', md: 'block' }} // Hide logo on small screens
          />
          <Image
            src="/logo-small.png"
            alt="Logo"
            width="30px"
            display={{ base: 'block', md: 'none' }} // Hide logo on small screens
          />
        </Flex>

        {/* Navigation Links */}
        <List spacing={4}>
          <ListItem
            as={RouterLink}
            to="/dashboard"
            display="flex"
            alignItems="center"
            cursor="pointer"
            _hover={{ bg: 'gray.700', borderRadius: 'md', padding: '0.5rem' }}
          >
            <Flex alignItems="center">
              <Icon as={MdDashboard} color="brand.500" boxSize={{ base: '2rem', md: '1.5rem' }} /> {/* Larger icon on small screens */}
              <Box ml={2} display={{ base: 'none', md: 'block' }}>Dashboard</Box> {/* Hide text on small screens */}
            </Flex>
          </ListItem>

          <ListItem
            as={RouterLink}
            to="/assistant"
            display="flex"
            alignItems="center"
            cursor="pointer"
            _hover={{ bg: 'gray.700', borderRadius: 'md', padding: '0.5rem' }}
          >
            <Flex alignItems="center">
              <Icon as={MdPerson} color="brand.500" boxSize={{ base: '2rem', md: '1.5rem' }} /> {/* Larger icon on small screens */}
              <Box ml={2} display={{ base: 'none', md: 'block' }}>Assistant</Box> {/* Hide text on small screens */}
            </Flex>
          </ListItem>

          {/* Phone Number Page Link */}
          <ListItem
            as={RouterLink}
            to="/phone-numbers"
            display="flex"
            alignItems="center"
            cursor="pointer"
            _hover={{ bg: 'gray.700', borderRadius: 'md', padding: '0.5rem' }}
          >
            <Flex alignItems="center">
              <Icon as={MdPhoneIphone} color="brand.500" boxSize={{ base: '2rem', md: '1.5rem' }} /> {/* Larger icon on small screens */}
              <Box ml={2} display={{ base: 'none', md: 'block' }}>Phone Numbers</Box> {/* Hide text on small screens */}
            </Flex>
          </ListItem>

          {/* Knowledgebase Page Link */}
          <ListItem
            as={RouterLink}
            to="/knowledgebase"
            display="flex"
            alignItems="center"
            cursor="pointer"
            _hover={{ bg: 'gray.700', borderRadius: 'md', padding: '0.5rem' }}
          >
            <Flex alignItems="center">
              <Icon as={MdLibraryBooks} color="brand.500" boxSize={{ base: '2rem', md: '1.5rem' }} /> {/* Larger icon on small screens */}
              <Box ml={2} display={{ base: 'none', md: 'block' }}>Knowledgebase</Box> {/* Hide text on small screens */}
            </Flex>
          </ListItem>
          {/* Tool Page Link */}
          <ListItem
            as={RouterLink}
            to="/tool"
            display="flex"
            alignItems="center"
            cursor="pointer"
            _hover={{ bg: 'gray.700', borderRadius: 'md', padding: '0.5rem' }}
          >
            <Flex alignItems="center">
              <Icon as={RiFunctionAddLine} color="brand.500" boxSize={{ base: '2rem', md: '1.5rem' }} /> {/* Larger icon on small screens */}
              <Box ml={2} display={{ base: 'none', md: 'block' }}>Tool</Box> {/* Hide text on small screens */}
            </Flex>
          </ListItem>

          <ListItem
            as={RouterLink}
            to="/settings"
            display="flex"
            alignItems="center"
            cursor="pointer"
            _hover={{ bg: 'gray.700', borderRadius: 'md', padding: '0.5rem' }}
          >
            <Flex alignItems="center">
              <Icon as={MdSettings} color="brand.500" boxSize={{ base: '2rem', md: '1.5rem' }} /> {/* Larger icon on small screens */}
              <Box ml={2} display={{ base: 'none', md: 'block' }}>Settings</Box> {/* Hide text on small screens */}
            </Flex>
          </ListItem>

          {/* Profile Link */}
          <ListItem
            as={RouterLink}
            to="/profile"
            display="flex"
            alignItems="center"
            cursor="pointer"
            _hover={{ bg: 'gray.700', borderRadius: 'md', padding: '0.5rem' }}
          >
            <Flex alignItems="center">
              <Icon as={MdAccountCircle} color="brand.500" boxSize={{ base: '2rem', md: '1.5rem' }} /> {/* Larger icon on small screens */}
              <Box ml={2} display={{ base: 'none', md: 'block' }}>Profile</Box> {/* Hide text on small screens */}
            </Flex>
          </ListItem>

          {/* Billing Link */}
          <ListItem
            as={RouterLink}
            to="/billing"
            display="flex"
            alignItems="center"
            cursor="pointer"
            _hover={{ bg: 'gray.700', borderRadius: 'md', padding: '0.5rem' }}
          >
            <Flex alignItems="center">
              <Icon as={MdPayment} color="brand.500" boxSize={{ base: '2rem', md: '1.5rem' }} /> {/* Larger icon on small screens */}
              <Box ml={2} display={{ base: 'none', md: 'block' }}>Billing</Box> {/* Hide text on small screens */}
            </Flex>
          </ListItem>

          {isAdmin &&
          <ListItem
            as={RouterLink}
            to="/admin/create-user"
            display="flex"
            alignItems="center"
            cursor="pointer"
            _hover={{ bg: 'gray.700', borderRadius: 'md', padding: '0.5rem' }}
          >
            <Flex alignItems="center">
              <Icon as={MdAdminPanelSettings} color="brand.500" boxSize={{ base: '2rem', md: '1.5rem' }} /> {/* Larger icon on small screens */}
              <Box ml={2} display={{ base: 'none', md: 'block' }}>Admin</Box> {/* Hide text on small screens */}
            </Flex>
          </ListItem>
        }
        </List>
      </Box>

      {/* Logout Button */}
      <Button
        onClick={handleLogout}
        leftIcon={<MdLogout size={iconSize} />} // Use dynamic icon size
        mt={8}
        width="full"
        colorScheme="red"
        >
        <Box display={{ base: 'none', md: 'block' }}>Logout</Box> {/* Hide text on small screens */}
        </Button>
    </Flex>
  );
};

export default Navigation;
