// src/components/assistant/ChatGPTPromptsTab.js
import React from 'react';
import {
  Heading, Grid, GridItem, VStack, FormControl, FormLabel, Input, Textarea, 
  Box, Flex, Divider,
  Slider, SliderTrack, SliderFilledTrack, SliderThumb, Text, Select
} from '@chakra-ui/react';
import FileSelect from './FileSelect';
import ToolSelect from './ToolSelect';

const ChatGPTPromptsTab = ({
  formState,
  handleInputChange,
  handleFileSelect,
  handleToolSelect,
  knowledgeBaseFiles,
  handleSliderChange,
  tools,
  modelRef
}) => {
    return (
    <Grid templateColumns={{
      base: 'repeat(1, 1fr)',  // 1 column on small screens (mobile)
      md: 'repeat(2, 1fr)',    // 2 columns on medium screens (tablets)
      lg: 'repeat(4, 1fr)' }}    // 4 columns on large screens (desktops)
      gap={6}>
      {/* Left Column */}
      <GridItem colSpan={3}>
        <VStack spacing={4} align="stretch">
        <FormControl isRequired>
        <FormLabel>Initial Greeting Message</FormLabel>
          <Input
            placeholder="Enter the first message"
            name="firstMessage"
            value={formState.firstMessage || ''}
            onChange={handleInputChange}
            bg="gray.900"
            color="gray.200"
          />
          </FormControl>
        <FormControl isRequired>
            <FormLabel>Instruction Prompt</FormLabel>
          <Textarea
            placeholder="Enter the system prompt"
            name="systemPrompt"
            value={formState.systemPrompt || ''}
            onChange={handleInputChange}
            bg="gray.900"
            color="gray.200"
            height="500px"
          />
          </FormControl>
        </VStack>
      </GridItem>

      {/* Right Column */}
      <GridItem>
        <VStack spacing={4} align="stretch">
        <FormControl isRequired>
        <FormLabel>Assistant Name</FormLabel>
          <Input
            placeholder="Enter assistant name"
            name="name"
            value={formState.name || ''}
            onChange={handleInputChange}
            bg="gray.900"
            color="gray.200"
          />
        </FormControl>
          <Flex direction="column">
            
            <Flex alignItems="center">
                <Text mr={2}>Temperature:</Text>
                
                <Text color="brand.400">{formState.temperature || 0.6}</Text>
            </Flex>
            
          
          <Slider
            aria-label="temperature-slider"
            value={formState.temperature || 0.5}
            onChange={(val) => handleSliderChange(val, 'temperature')}
            min={0}
            max={1}
            step={0.1}
          >
            <SliderTrack bg="gray.600">
              <SliderFilledTrack bg="blue.400" />
            </SliderTrack>
            <SliderThumb />
          </Slider>
          <Text fontSize="sm" mr={2}>Note: If you are using RealTime, set this to 0.6 and above</Text>
          </Flex>

        <FormControl isRequired>
          <FormLabel>Max Output Token</FormLabel>
          <Input
            placeholder="Enter max tokens"
            name="maxTokens"
            type="number"
            value={formState.maxTokens || ''}
            onChange={handleInputChange}
            bg="gray.900"
            color="gray.200"
          />
        </FormControl>
        
          <FormControl isRequired>
            <FormLabel>LLM Models</FormLabel>
            <Select 
            ref={modelRef}
            name="model" 
            bg="gray.900" 
            color="gray.100"
            value={formState.model}
            onChange={handleInputChange} required={true} colorScheme="orange">
                <option value='complete:gpt-4o-mini-2024-07-18'>GPT-4o mini</option>
                <option value='complete:gpt-4o-2024-08-06'>GPT-4o</option>
                <option value='realtime:gpt-4o-realtime-preview-2024-10-01'>GPT-4o Realtime API</option>
            </Select>
          </FormControl>
          <Box mt={6} p={4} borderWidth="1px" borderRadius="md" boxShadow="lg" bg="white">
            <Heading size="xs" mb={4}>Attach Knowledge Base Files</Heading>
            <FileSelect
                formState={formState}
                handleFileSelect={handleFileSelect}
                knowledgeBaseFiles={knowledgeBaseFiles}
            />
          </Box>
          <Box mt={6} p={6} borderWidth="1px" borderRadius="md" boxShadow="lg" bg="white">
            <Heading size="xs" mb={4}>Attach Tools</Heading>
            <Divider mb={4} />

            <ToolSelect
                formState={formState}
                handleToolSelect={handleToolSelect}
                tools={tools}
            />

          </Box>
        </VStack>
      </GridItem>
    </Grid>
)};

export default ChatGPTPromptsTab;